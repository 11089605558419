import {Question} from './Question';

const questions: Question[] = [
    {
        question: "What is a Process Instance?",
        options: [
            "Another word for Process Engine",
            "Another word for Process Model",
            "One execution of a Service Task",
            "One execution of a Process Definition",
        ],
        answers: [3],
    },
    {
        question: "Why do we need to configure a Task Definition for a Service Task?",
        options: [
            "The Task Definition is used by the Zeebe Engine to call the appropriate microservice",
            "The Task Definition determines the visibility of the task in Tasklist",
            "The Task Definition is an identifier which can be used to filter tasks in Operate",
            "The Task Definition defines which Job Workers may execute a task",
        ],
        answers: [3],
    },
    {
        question: "Which RPC should be sent when a Job Handler has processed a Job?",
        options: [
            "CreateProcessInstance",
            "CompleteJob",
            "SetVariables",
            "ActivateJobs",
        ],
        answers: [1],
    },
    {
        question: "Which of the following is true regarding the Timeout parameter in the ActivateJob command?",
        options: [
            "It will set a higher priority for the Worker",
            "If the Timeout is reached, the Worker will stop working on that Job",
            "The Timeout is used to estimate the end to end process execution time",
            "It is the period of time in which Job is exclusively locked for the Worker that acquired it",
        ],
        answers: [3],
    },
    {
        question: "Process Instances can be started with Process Variables in the Desktop Modeler by providing a ... ?",
        options: [
            "JSON Object",
            "CSV File",
            "XML Snippet",
            "Process Variables cannot be provided using the Desktop Modeler",
        ],
        answers: [0],
    },
    {
        question: "Process Variables can be ...",
        options: [
            "Read by a Job Worker",
            "Updated by a Job Worker",
            "Read and Updated by a Job Worker",
        ],
        answers: [2],
    },
    {
        question: "Operate will display the path taken by a Process Instance that has completed",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "Running processes instances can be deleted from Operate",
        options: [
            "True",
            "False",
        ],
        answers: [1],
    },
    {
        question: "Process Variables on a Job can be retrieved using into which Java collection?",
        options: [
            "List",
            "Map",
            "Array",
            "Set",
        ],
        answers: [1],
    },
    {
        question: "Which of the following parameters cannot be set when registering a new worker with the Java Client?",
        options: [
            "Handler",
            "Job Type",
            "Batch Size",
            "Timeout",
        ],
        answers: [2],
    },
    {
        question: "Which of the following clients are officially supported by Camunda? (Select 3)",
        options: [
            "C#",
            "Go",
            "Python",
            "CLI",
            "Java",
        ],
        answers: [1, 3, 4],
    },
    {
        question: "The Desktop Modeler can be used to start a new process instance with process variables?",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "Which of the following is not a scope for Client Credentials?",
        options: [
            "Optimize",
            "Tasklist",
            "Gateway",
            "Zeebe",
            "Operate",
        ],
        answers: [2],
    },
    {
        question: "Zeebe Brokers form a peer to peer network and are responsible for managing and coordinating workflow instances.",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "The highly recommended deployment for Camunda 8 Self-Managed  in production is:",
        options: [
            "Docker including Docker Compose",
            "Helm/Kubernetes independent of where this is hosted",
            "Local installation",
        ],
        answers: [1],
    },
    {
        question: "SaaS offering includes the WebApp Console, which allows you to:",
        options: [
            "Analyze reports and dashboards, and obtain insights from charts.",
            "Manage the human tasks in your processes.",
            "Manage Clusters, API clients, Connector Secrets.",
        ],
        answers: [2],
    },
    {
        question: "The components of a Connector are (Select two):",
        options: [
            "Connector Component",
            "Connector Runtime",
            "Connector Function",
            "Connector Template",
        ],
        answers: [2, 3],
    },
    {
        question: "Secrets allow you to manage sensitive information such as Usernames and Passwords in your Connectors?",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "Out of the Box Connectors are available for which of the following (Select all that apply):",
        options: [
            "Amazon Web Services",
            "Slack",
            "Google Drive",
            "Microsoft Teams",
        ],
        answers: [0, 1, 2, 3],
    },
    {
        question: "Connectors in Camunda Platform 8 can be either:",
        options: [
            "Checked or Unchecked",
            "Inbound or Outbound",
            "BPMN or DMN",
            "Static or Dynamic",
        ],
        answers: [1],
    },
    {
        question: "A secret which has been named MY_API_KEY can be accessed from a Connector by using which of the following?",
        options: [
            "{{secrets.MY_API_KEY}}",
            "cluster.MY_API_KEY",
            "environment.MY_API_KEY",
            "MY_API_KEY",
        ],
        answers: [0],
    },
    {
        question: "Which of the following instructions will deploy the process? The process definition is \"processA\" and it is stored in the file with the name \"processA.bpmn\"?",
        options: [
            "zbctl deploy processA.bpmn",
            "zbctl deploy process processA.bpmn",
            "zbctl deploy processA",
            "zbctl deploy process processA",
        ],
        answers: [0],
    },
    {
        question: "Which of the following instructions will start a new process instance of the process definition \"processA\" which has already been deployed?",
        options: [
            "zbctl start processA",
            "zbctl create instance processA",
            "zbctl create instance processA.bpmn",
            "zbctl start processA.bpmn",
        ],
        answers: [1],
    },
    {
        question: "Select the answer which satisfies this requirement:\n" +
            "\n" +
            "I want to complete the Service Task \"Validate application\" by sending \"validated: true\" as the response.\n" +
            "\n" +
            "Assume 2251799819509629 as the job key.",
        options: [
            "zbctl create instance ApplicationProcess --variables \"{\"validated\":true}\"",
            "zbctl fail job 2251799819509629 --variables \"{\"validated\":true}\"",
            "zbctl create worker validateApplication --handler \"echo {\"validated\":true}\"",
            "zbctl activate jobs validateApplication --variables \"{\"validated\":true}\"",
        ],
        answers: [2],
    },
    {
        question: "Which statement best describes the difference between a message event and a signal event?",
        options: [
            "Message events have a 1-to-many relationship, while signal events have a 1-to-1 relationship.",
            "Message events have a 1-to-1 relationship, while signal events have a 1-to-many relationship.",
        ],
        answers: [1],
    },
    {
        question: "Which of these possibilities to connect processes require the processes to be deployed to the same cluster?",
        options: [
            "Signal events",
            "Call activities",
            "Message events",
        ],
        answers: [1],
    },
    {
        question: "Which is a valid scenario for using an event subprocess?",
        options: [
            "You want a catching event that is triggerable throughout an entire process.",
            "You want to call another process directly.",
        ],
        answers: [0],
    },
    {
        question: "The connector template is one of the connector's main components. But, what is the connector template used for?",
        options: [
            "It represents the logic of the connector",
            "It represents the input and output data declaration",
        ],
        answers: [1],
    },
    {
        question: "The connector runtime uses the application.properties file to connect to the engine.  For which scopes do you have to create the client credentials of your cluster?",
        options: [
            "Zeebe",
            "Operate",
            "TaskList",
            "Zeebe and Operate",
        ],
        answers: [3],
    },
    {
        question: "There is an important attribute used to identify the connector template in the @OutboundConnector annotation inside the Connector Function. Which one?",
        options: [
            "inputVariables",
            "type",
            "name",
        ],
        answers: [1],
    },
    {
        question: "The connector implementation needs to be run in, which of the following components?",
        options: [
            "Zeebe runtime",
            "Operate runtime",
            "Connector runtime",
            "Modeler runtime",
        ],
        answers: [2],
    },
    {
        question: "An essential component of the Camunda connector setup is the connector template. How does it assist in the customization of connectors for different processes?",
        options: [
            "By enabling the reuse of connector configurations across different BPMN diagrams",
            "By allowing the adjustment of the Camunda engine's performance settings",
            "By facilitating the translation of BPMN models into executable code",
            "By providing a standardized interface for user task assignment",
        ],
        answers: [0],
    },
    {
        question: "A crucial part of the connector's configuration is the connector event file. What is the connector event file primarily used for in a Camunda Inbound Connector?",
        options: [
            "To store the persistent state of all process instances that the connector interacts with",
            "To define and handle custom events that the connector should respond to",
            "To act as a log file for recording the timestamps of process executions",
            "To serve as a backup of the process definitions associated with the connector",
        ],
        answers: [1],
    },
    {
        question: "The connector executable file plays a pivotal role in the operation of a Camunda Inbound Connector. What is the primary function of the connector executable file?",
        options: [
            "To document the connector's configuration and setup instructions",
            "To launch the connector's runtime environment and initiate its operations",
            "To provide a graphical interface for monitoring the connector's status",
            "To act as a template for defining new instances of the connector",
        ],
        answers: [1],
    },
    {
        question: "What is the importance of the @InboundConnector annotation in the development of a custom Camunda Inbound Connector?",
        options: [
            "It dictates the security protocols the connector will use when communicating with the Camunda engine",
            "It serves as a marker that identifies and provides metadata for the connector, such as its name and type, to align with the connector template settings",
            "It specifies the user roles allowed to manage and deploy the connector within the Camunda admin console",
            "It outlines the graphical elements that will be used to represent the connector in the BPMN diagrams",
        ],
        answers: [1],
    },
    {
        question: "A colleague asks you for help to fix an error that appears in the application log. Apparently, the complete job command was not successful due to the following error: \n" +
            " \n" +
            "Command 'COMPLETE' rejected with code 'NOT_FOUND'\n" +
            " \n" +
            "Which of the following configurations would resolve this issue?",
        options: [
            "Increase the worker timeout",
            "Increase the worker pollInterval",
            "Increase the worker requestTimeout",
            "Increase the worker fetchVariables",
        ],
        answers: [0],
    },
    {
        question: "How do Zeebe and Workers work with jobs?",
        options: [
            "Zeebe pushes jobs to the workers",
            "Workers queue jobs and Zeebe request them",
            "Zeebe queues jobs and the workers request them",
            "Workers push jobs to Zeebe",
        ],
        answers: [2],
    },
    {
        question: "In your job client, you can define which process variables you will need in your worker, and only these will be read and transferred, saving resources on the broker as well as network bandwidth.\n" +
            "\n" +
            "Which command makes this possible?",
        options: [
            "requestVariables",
            "retrieveVariables",
            "fetchVariables",
            "inputVariables",
        ],
        answers: [2],
    },
    {
        question: "BPMN Errors can be thrown directly from a Job Worker?",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "Incidents can be viewed and resolved from which application?",
        options: [
            "Tasklist",
            "Operate",
            "Optimize",
            "Modeler",
        ],
        answers: [1],
    },
    {
        question: "The Modeler can be used to start a new process instance with process variables?",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "In the event of an error, Jobs will be retried how many times by default?",
        options: [
            "3",
            "2",
            "1",
            "4",
        ],
        answers: [0],
    },
    {
        question: "Unhandled exceptions that occur during process execution result in an Incident? True or False",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "What is the purpose of an execution listener in Camunda?",
        options: [
            "To react to specific lifecycle events in a business process",
            "To deploy the process to the Camunda engine",
            "To execute business logic as a service task",
            "To model the process diagram",
        ],
        answers: [0],
    },
    {
        question: "What is the behavior of an execution listener in the workflow execution lifecycle in Camunda?",
        options: [
            "An execution listener is a non-blocking operation that allows the workflow to proceed before the listener completes.",
            "An execution listener runs in parallel to the workflow, and its completion does not affect the workflow's progression.",
            "An execution listener is a blocking operation that ensures the workflow only continues once the listener has completed.",
            "An execution listener is an optional operation that can be skipped if the workflow needs to proceed quickly to the next element.",
        ],
        answers: [2],
    },
    {
        question: "What happens if multiple execution listeners are configured on the same event type and activity?",
        options: [
            "Only the last listener in the XML will be executed",
            "The process engine will throw an exception",
            "All listeners will be executed in the order they are defined",
            "Only the first listener in the XML will be executed",
        ],
        answers: [2],
    },
    {
        question: "An execution listener configured with the \"start\" event on a task will execute when:",
        options: [
            "The user starts to work on the task",
            "The process instance is started",
            "The task is completed",
            "The task has been created and before any task logic is performed",
        ],
        answers: [3],
    },
    {
        question: "Which of the following statements are true about the @ZeebeProcessTest annotation on your test class?",
        options: [
            "It creates a client which can be used to interact with the test engine",
            "It deploys your processes that are located in src/main/resources to the test engine",
            "It injects the Zeebe client to the test class",
            "It manages the lifecycle of the testcontainer/embedded engine",
        ],
        answers: [0, 2, 3],
    },
    {
        question: "In which testing scope(s) do we usually test the \"Glue Code\" of our process application? (Multiple Answers Possible)",
        options: [
            "Integration Test",
            "Unit Tests",
            "Process Test",
        ],
        answers: [1, 2],
    },
    {
        question: "If you are using Java 17, which is the preferred dependency to use for your tests?",
        options: [
            "zeebe-process-test-extension",
            "zeebe-process-test-extension-testcontainer",
        ],
        answers: [0],
    },
    {
        question: "Is the following statement true? If you test a User Task in your process, you have to activate and complete a job using the ZeebeClient.",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "Is the following statement true? Process tests are usually implemented as unit tests.",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
];

export default questions;
