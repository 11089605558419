import React from 'react';
import QuestionPools, {QuestionPool as QuestionPoolType} from '../data/questions/QuestionPools';
import {Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography, Box, Paper} from '@mui/material';
import Quiz from './Quiz';
import questionPools from "../data/questions/QuestionPools";

const Overview: React.FC = () => {
    const [selectedMaxQuestions, setSelectedMaxQuestions] = React.useState<number>(0);
    const [selectedQuestionPools, setSelectedQuestionPools] = React.useState<QuestionPoolType[]>([]);
    const [isQuizStarted, setIsQuizStarted] = React.useState<boolean>(false);

    const topics = Array.from(new Set(questionPools.map(e => e.topic)));

    return (
        <Box>
            <Paper sx={{ padding: 3 }}>
                {
                    isQuizStarted
                        ? (
                            <Quiz
                                questionPools={selectedQuestionPools}
                                maxQuestions={selectedMaxQuestions}
                                onFinish={() => {
                                    setIsQuizStarted(false);
                                    setSelectedQuestionPools([]);
                                }}
                            />
                        ) : (
                            <>
                                <TextField
                                    label="Maximale Anzahl an Fragen (0 = keine Beschränkung)"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    value={selectedMaxQuestions}
                                    onChange={(event) => setSelectedMaxQuestions(Number(event.target.value))}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Typography variant="body1" component="div">
                                    Welche Fragen Pools soll das Quiz enthalten?
                                </Typography>
                                <FormGroup>
                                    {
                                        topics.map((topic, index) => (
                                            <React.Fragment key={index}>
                                                <Typography variant="subtitle1" component="div" sx={{ marginTop: 2 }}>
                                                    {topic}
                                                </Typography>
                                                {
                                                    QuestionPools.filter(e => e.topic === topic).map((questionPool, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedQuestionPools.some(e => e.name === questionPool.name)}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        if (event.target.checked) {
                                                                            setSelectedQuestionPools([...selectedQuestionPools, questionPool]);
                                                                        } else {
                                                                            setSelectedQuestionPools(selectedQuestionPools.filter(e => e.name !== questionPool.name));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={`${questionPool.name} (${questionPool.questions.length} Fragen)`}
                                                        />
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </FormGroup>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={selectedQuestionPools.length < 1}
                                    onClick={() => setIsQuizStarted(true)}
                                    sx={{ marginTop: 2 }}
                                >
                                    Quiz starten
                                </Button>
                            </>
                        )
                }
            </Paper>
        </Box>
    );
};

export default Overview;
