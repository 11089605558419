import bpmn from './bpmn';
import camundaOverview from './camunda-overview';
import developmentProcess from './development-process';
import dmn from './dmn';
import feel from './feel';
import mixed from './mixed';
import processModeling from './process-modeling';
import {Question as QuestionType} from './Question';

export interface QuestionPool {
    name: string;
    description: string;
    questions: QuestionType[];
    topic: string;
}

const questionPools: QuestionPool[] = [
    {
        name: 'bpmn',
        description: 'fragen zu bpmn',
        topic: 'camunda',
        questions: bpmn,
    },
    {
        name: 'camunda-overview',
        description: 'fragen zu camunda',
        topic: 'camunda',
        questions: camundaOverview,
    },
    {
        name: 'development-process',
        description: 'fragen zum camunda development process',
        topic: 'camunda',
        questions: developmentProcess,
    },
    {
        name: 'dmn',
        description: 'fragen zu dmn',
        topic: 'camunda',
        questions: dmn,
    },
    {
        name: 'feel',
        description: 'fragen zu feel',
        topic: 'camunda',
        questions: feel,
    },
    {
        name: 'mixed',
        description: 'fragen zu über alle themen hinweg',
        topic: 'camunda',
        questions: mixed,
    },
    {
        name: 'process-modeling',
        description: 'fragen zu prozess modelierung',
        topic: 'camunda',
        questions: processModeling,
    },
];

export default questionPools;